<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="4" lg="6">
                    <ValidationProvider name="code" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('code')">
                            <b-form-input type="text" v-model="formData.code"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="6">
                    <ValidationProvider name="faculty_code" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="formData.faculty_code"
                                               :validate-error="errors[0]">
                            </faculty-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="12">
                    <b-form-group :label="$t('name')">
                        <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1"
                                           :class="errors[0] ? 'box-border-color-for-input-group' : ''"
                                           prepend="TR">
                                <b-form-input v-model="formData.name"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                        <ValidationProvider name="name_en" v-slot="{valid, errors}">
                            <b-input-group class="mb-1"
                                           :class="errors[0] ? 'box-border-color-for-input-group' : ''"
                                           prepend="EN">
                                <b-form-input v-model="formData.name_en"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group :label="$t('short_name')">
                        <ValidationProvider name="short_name" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1"
                                           :class="errors[0] ? 'box-border-color-for-input-group' : ''"
                                           prepend="TR">
                                <b-form-input v-model="formData.short_name"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                        <ValidationProvider name="short_name_en" v-slot="{valid, errors}">
                            <b-input-group class="mb-1"
                                           :class="errors[0] ? 'box-border-color-for-input-group' : ''"
                                           prepend="EN">
                                <b-form-input v-model="formData.short_name_en"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group :label="$t('diploma_name')">
                        <ValidationProvider name="diploma_name" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1"
                                           :class="errors[0] ? 'box-border-color-for-input-group' : ''"
                                           prepend="TR">
                                <b-form-input v-model="formData.diploma_name"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                        <ValidationProvider name="diploma_name_en" v-slot="{valid, errors}">
                            <b-input-group class="mb-1"
                                           :class="errors[0] ? 'box-border-color-for-input-group' : ''"
                                           prepend="EN">
                                <b-form-input v-model="formData.diploma_name_en"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <ValidationProvider name="head_of_department" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('head_of_department')">
                            <staff-auto-complete v-model="formData.head_of_department"
                                                 :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col>
                    <ValidationProvider name="credit_limit" v-slot="{valid, errors}">
                        <b-form-group :label="$t('credit_limit')">
                            <b-form-input type="number"
                                          v-model="formData.credit_limit"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col>
                    <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('status')">
                            <status-selectbox v-model="formData.status"
                                              :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="createForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox';
    import StaffAutoComplete from '@/components/interactive-fields/StaffAutoComplete';
    import StatusSelectbox from '@/components/interactive-fields/StatusSelectbox';

    // Services
    import DepartmentsService from '@/services/DepartmentsService';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            FacultySelectbox,
            StaffAutoComplete,
            StatusSelectbox,

            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                formData: {
                    code: null,
                    name: null,
                    name_en: null,
                    short_name: null,
                    short_name_en: null,
                    diploma_name: null,
                    diploma_name_en: null,
                    head_of_department: null,
                    credit: null,
                    faculty_code: null,
                    status: null
                },
                formLoading: false
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    DepartmentsService.store(this.formData)
                                      .then(response => {
                                          this.$emit('createFormSuccess', true);
                                          this.$toast.success(this.$t('api.' + response.data.message));
                                      })
                                      .catch(e => {
                                          this.showErrors(e, this.$refs.formModalValidate);
                                      })
                                      .finally(() => {
                                          this.formLoading = false
                                      })
                }
            }
        }
    }
</script>

